<template>
  <div class="draft_page">
    <div class="draft_list">
      <div class="draft_item" v-for="(item, index) in draftList">
        <h4>{{ item.title }}</h4>
        <p>{{ item.topic ? "#" + item.topic + "#" : "" }}{{ item.introduction }}</p>
        <div>
          <a @click="publish(item.id)">发布</a><span>|</span>
          <a @click="del(item.id)">删除</a><span>|</span>
          <a @click="edit(item.id)">编辑</a>
          <span>{{ item.dated }}</span>
        </div>
      </div>
    </div>
    <div class="noData" v-if="this.draftList.length == 0">
      <img src="@/assets/PC/profile/listNone.png" alt="" />
    </div>
    <div class="draft_pagination" v-if="recordCount > pageSize">
      <el-pagination
        background
        layout="pager, next"
        next-text="下一页"
        :page-size="pageSize"
        :pager-count="pageCount"
        :total="recordCount"
        @current-change="pageClick"
        :current-page="listParams.page"
        @next-click="pageClick"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Headers from "@/components/header.vue";
import * as forum from "../../api/forum.js";
import { debounce } from "@/utils/common";
import { dataState } from "@/api/all.js";

export default {
  name: "draft",
  components: {
    Headers,
  },
  props: ["searchVal", "isSearch"],
  watch: {
    searchVal(val) {
      this.searchTitle = val;
    },
    isSearch(val) {
      if (val) {
        this.search();
      }
    },
  },
  created() {
    this.$emit("change", 2, "草稿箱");
    this.getList();
  },
  data() {
    return {
      searchTitle: "",
      pageSize: 5,
      pageCount: 5,
      recordCount: 0,
      listParams: {
        page: 1,
        limit: 5,
        productType: 0,
        title: "",
      },
      draftList: [],
    };
  },
  methods: {
    search() {
      this.listParams.page = 1;
      this.listParams.title = this.searchTitle;
      this.getList();
    },
    getList() {
      forum.getDraft(this.listParams).then((res) => {
        if (res.status == 1) {
          this.draftList = res.data;
          this.recordCount = res.recordCount;
        }
      });
    },
    pageClick(page) {
      this.listParams.page = page;
      this.getList();
    },
    // !发布
    publish(id) {
      debounce(() => {
        forum.publishForum({ forumManagementId: id }).then((res) => {
          if (res.status == 1) {
            this.$toast({ message: res.message, duration: 1000 });
            this.getList();
          }
        });
      }, 3000);
    },
    del(id) {
      forum.delArticle({ forumId: id }).then((res) => {
        if (res.status == 1) {
          this.$toast({ message: res.message, duration: 1000 });
          this.getList();
        }
      });
    },
    edit(id) {
      this.$router.push("/Home/forumSec/Article?id=" + id);
    },
  },
};
</script>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.draft_page {
  .draft_list {
    .draft_item {
      padding: 40px;
      border-color: #e6e6e6;
      border-style: solid;
      border-width: 0 1px 1px 1px;
      h4 {
        font-size: 16px;
        color: #363d42;
      }
      p {
        font-size: 16px;
        color: #738294;
        line-height: 26px;
        margin: 20px 0 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      div {
        display: flex;
        align-items: center;
        font-size: 16px;
        a,
        span {
          margin-right: 12px;
        }
        a {
          color: #5f1593;
          text-decoration: underline;
          cursor: pointer;
          &:last-of-type {
            margin-right: 24px;
          }
        }
        span {
          color: #738294;
        }
      }
      &:first-of-type {
        border-width: 1px;
      }
      &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .draft_pagination {
    margin: 60px 0 100px 0;

    .el-pagination {
      display: flex;
      justify-content: flex-end;

      /deep/ .el-pager li {
        width: 45px;
        height: 45px;
        font-size: 16px;
        color: #363d42;
        text-align: center;
        line-height: 45px;
      }

      /deep/ .el-pager li.active {
        color: #fff;
        background-color: #6b18a8;
      }

      /deep/ .btn-next {
        width: 95px;
        height: 45px;
        color: #fff;
        background-color: #6b18a8;
      }
    }
  }
}
</style>
